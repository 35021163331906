$(function () {
  $(document).on("click", "#scroll", function (e) {
    $("html, body").animate({ scrollTop: 0 }, 600);
    return false;
  });

  $(window).on("scroll", function (params) {
    if ($(window).scrollTop() > 150) {
      // $(".top_nav").fadeOut("fast");
      $("#nav_with_slide").css({ display: "block" });
    } else {
      $("#nav_with_slide").css({ display: "none" });

      // $(".top_nav").fadeIn("fast");
      // $("#nav_with_slide").slideUp("fast");
    }
  });

  $(document).on("click", ".clickAnchor", function (params) {
    $("#seriveMenu").slideUp("fast");
    $("#ourStoriesMenu").slideUp("fast");
    $(".seriveMenu span").removeClass("toggleCaret");
    $(".ourStoriesMenu span").removeClass("toggleCaret");

    $("#CompanyeMenu").slideToggle("fast");
    $(".clickAnchor span").toggleClass("toggleCaret");
  });

  $(document).on("click", ".seriveMenu", function (params) {
    $("#CompanyeMenu").slideUp("fast");
    $("#ourStoriesMenu").slideUp("fast");
    $(".clickAnchor span").removeClass("toggleCaret");
    $(".ourStoriesMenu span").removeClass("toggleCaret");

    $("#seriveMenu").slideToggle("fast");
    $(".seriveMenu span").toggleClass("toggleCaret");
  });

  $(document).on("click", ".ourStoriesMenu", function (params) {
    $("#CompanyeMenu").slideUp("fast");
    $("#seriveMenu").slideUp("fast");
    $(".clickAnchor span").removeClass("toggleCaret");
    $(".seriveMenu span").removeClass("toggleCaret");

    $("#ourStoriesMenu").slideToggle("fast");
    $(".ourStoriesMenu span").toggleClass("toggleCaret");
  });

  // All content Mobile Menu

  $(document).on("click", "#company_drb", function (params) {
    // close service DDL
    $("#service_DDL").slideUp("fast");
    $("#srories_DDL").slideUp("fast");
    $("#service_drb span").removeClass("toggleCaret");
    $("#stories_drb span").removeClass("toggleCaret");

    $("#compantList").slideToggle("fast");
    $("#company_drb span").toggleClass("toggleCaret");
  });

  $(document).on("click", "#service_drb", function (params) {
    $("#srories_DDL").slideUp("fast");
    $("#stories_drb span").removeClass("toggleCaret");

    $("#service_DDL").slideToggle("fast");
    $("#service_drb span").toggleClass("toggleCaret");
  });

  $(document).on("click", "#stories_drb", function (params) {
    $("#srories_DDL").slideToggle("fast");
    $("#stories_drb span").toggleClass("toggleCaret");
  });

  $(document).on("click", "#toggleBtn", function (params) {
    $("#navbar").slideToggle("fast");
  });

  // $(document).on("click", "#navbar", function (e) {
  //   $(".navbar-collapse").collapse("hide");
  // });

  $(document).on("click", ".dropdown-menu li", function (e) {
    $(".navbar-collapse").slideUp("fast");
  });
});


// var counters = $(".count");
// var countersQuantity = counters.length;
// var counter = [];

// for (i = 0; i < countersQuantity; i++) {
//   counter[i] = parseInt(counters[i].innerHTML);
// }

// var count = function (start, value, id) {
//   var localStart = start;
//   setInterval(function () {
//     if (localStart < value) {
//       localStart++;
//       counters[id].innerHTML = localStart;
//     }
//   }, 40);
// };

// for (j = 0; j < countersQuantity; j++) {
//   count(0, counter[j], j);
// }

// console.log($(".navbar-nav"))
